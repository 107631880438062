import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Hr, IconButton, Input, InputGroup, Label, Select } from "../../EditorStyles";
import { ReactComponent as TrashIcon } from "./../../../../images/editor-icons/trash.svg";
import { ReactComponent as CloseIcon } from "./../../../../images/editor-icons/x.svg";
import { ReactComponent as OpenIcon } from "./../../../../images/editor-icons/external-link.svg";
import { FormattedMessage } from "react-intl";
import { useEditState } from "../../EditContext";
import ConfirmDialog from "../../BaseComponents/ConfirmDialog";
import { useRestAuth } from "../../../../hooks/useRestAuth";
import {
  BasicEditorMapPano,
  BasicEditorMapPoi,
  EditorData,
  EditorMapPano,
  EditorMapPoi,
  SelectedLink,
} from "../../../../utils/editorUtils";
import SpinnerWrapper from "../../BaseComponents/SpinnerWrapper";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../../../AppContext";

interface MapLinkEditorProps {
  selectedLink: SelectedLink;
  onCloseEditor: () => void;
  setSelectedLink: React.Dispatch<React.SetStateAction<SelectedLink | undefined>>;
  coordinateInputsLoading: boolean;
}

const MapLinkEditor: React.FC<MapLinkEditorProps> = ({ selectedLink, onCloseEditor, setSelectedLink, coordinateInputsLoading }) => {
  const { mapPanoRepository, mapPoiRepository, data, editData } = useEditState();
  const restAuth = useRestAuth();
  const history = useHistory();
  const [deleteLink, setDeleteLink] = useState(false);
  const { state } = useAppState();
  const linkData: EditorMapPano | EditorMapPoi | undefined = useMemo(() => {
    if (selectedLink.type === "pano") {
      return (data as EditorData<"map">).panorama_location.find(
        (location) => location.pano === selectedLink.item
      );
    }
    return (data as EditorData<"map">).poi_location.find(
      (location) => location.poi === selectedLink.item
    );
  }, [selectedLink, data]);
  const [targets, setTargets] = useState<BasicEditorMapPano[] | BasicEditorMapPoi[]>([]);
  const [targetInputLoading, setTargetInputLoading] = useState<boolean>(false);
  const [colorInputLoading, setColorInputLoading] = useState<boolean>(false);
  const [lat, setLatValue] = useState("0");
  const [long, setLongValue] = useState("0");

  useEffect(() => {
    if (linkData) {
      setLatValue(linkData.lat.toString() || "0");
      setLongValue(linkData.long.toString() || "0");
    }
  }, [linkData]);

  useEffect(() => {
    const fetchData = async () => {
      if (restAuth) {
        setTargetInputLoading(true);
        let targets = [] as BasicEditorMapPano[] | BasicEditorMapPoi[];
        if (selectedLink.type === "pano") {
          const all_targets = await mapPanoRepository.get(restAuth);
          const unlinked_targets = await mapPanoRepository.get_unused(restAuth);
          targets = [
            ...unlinked_targets,
            ...all_targets.filter((target) => target.pano === selectedLink.item),
          ];
        } else {
          const all_targets = await mapPoiRepository.get(restAuth);
          const unlinked_targets = await mapPoiRepository.get_unused(restAuth);
          targets = [
            ...unlinked_targets,
            ...all_targets.filter((target) => target.poi === selectedLink.item),
          ];
        }
        setTargets(targets);
        setTargetInputLoading(false);
      }
    };

    fetchData();
  }, [mapPanoRepository, mapPoiRepository, restAuth, selectedLink.type, selectedLink.item]);

  const onDeleteLink = async () => {
    if (restAuth) {
      if (selectedLink.type === "pano") {
        const links = await mapPanoRepository.delete(restAuth, selectedLink.item);
        const unusedPanoLinks = await mapPanoRepository.get_unused(restAuth);
        editData<"map">({ panorama_location: links, unused_panorama_location: unusedPanoLinks });
      } else {
        const links = await mapPoiRepository.delete(restAuth, selectedLink.item);
        const unusedPoiLinks = await mapPoiRepository.get_unused(restAuth);
        editData<"map">({ poi_location: links, unused_poi_location: unusedPoiLinks });
      }
      setDeleteLink(false);
      onCloseEditor();
    }
  };

  const setTarget = useCallback(
    async (linkTarget: number) => {
      if (restAuth) {
        setTargetInputLoading(true);
        if (selectedLink.type === "pano") {
          const previous_links = (data as EditorData<"map">).panorama_location;
          const links = await mapPanoRepository.update(
            restAuth,
            { pano: linkTarget },
            selectedLink.item
          );
          const unusedLinks = await mapPanoRepository.get_unused(restAuth);
          editData<"map">({ panorama_location: links, unused_panorama_location: unusedLinks });
          const newLink = links.find(
            (link) => !previous_links.some((prevLink) => prevLink.pano === link.pano)
          );
          setSelectedLink({ type: "pano", item: newLink?.pano || 0 });
        } else {
          const previous_links = (data as EditorData<"map">).poi_location;
          const links = await mapPoiRepository.update(
            restAuth,
            { poi: linkTarget },
            selectedLink.item
          );
          const unusedLinks = await mapPoiRepository.get_unused(restAuth);
          editData<"map">({ poi_location: links, unused_poi_location: unusedLinks });
          const newLink = links.find(
            (link) => !previous_links.some((prevLink) => prevLink.poi === link.poi)
          );
          setSelectedLink({ type: "poi", item: newLink?.poi || 0 });
        }
        setTargetInputLoading(false);
      }
    },
    [data, editData, mapPanoRepository, mapPoiRepository, restAuth, selectedLink.item, selectedLink.type, setSelectedLink]
  );

  const setColor = useCallback(
    async (linkType: string) => {
      // if (target) {
      //   setColorInputLoading(true);
      //   const updateLinkData: {
      //     hotspot_type?: string;
      //     yaw?: number;
      //     pitch?: number;
      //     hotspot_coordinates?: [{ pitch: number; yaw: number }];
      //   } = {};
      //   updateLinkData.hotspot_type = linkType;
      //   if (selectedLink.type === "pano") {
      //     const links = await mapPanoRepository.update(
      //       restAuth,
      //       updateLinkData,
      //       selectedLink.item,
      //     );
      //     editData<"map">({ panorama_location: links });
      //   } else {
      //     const links = await mapPoiRepository.update(
      //       restAuth,
      //       updateLinkData,
      //       selectedLink.item,
      //     );
      //     editData<"map">({ poi_location: links });
      //   }
      //   setColorInputLoading(false);
      // }
    },
    []
  );

  const setLat = useCallback(
    async (newLat: number) => {
      if (restAuth) {
        if (selectedLink.type === "pano") {
          const links = await mapPanoRepository.update(
            restAuth,
            { lat: newLat },
            selectedLink.item
          );
          editData<"map">({ panorama_location: links });
        } else {
          const links = await mapPoiRepository.update(restAuth, { lat: newLat }, selectedLink.item);
          editData<"map">({ poi_location: links });
        }
      }
    },
    [editData, mapPanoRepository, mapPoiRepository, restAuth, selectedLink.item, selectedLink.type]
  );

  const setLong = useCallback(
    async (newLong: number) => {
      if (restAuth) {
        if (selectedLink.type === "pano") {
          const links = await mapPanoRepository.update(
            restAuth,
            { long: newLong },
            selectedLink.item
          );
          editData<"map">({ panorama_location: links });
        } else {
          const links = await mapPoiRepository.update(
            restAuth,
            { long: newLong },
            selectedLink.item
          );
          editData<"map">({ poi_location: links });
        }
      }
    },
    [editData, mapPanoRepository, mapPoiRepository, restAuth, selectedLink.item, selectedLink.type]
  );

  if (linkData === undefined) return null;

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>
          <FormattedMessage
            id="editor.map.link.title"
            defaultMessage="Edit link"
            description="Text for map link editor title"
          />
        </h1>
        <IconButton style={{ position: "relative", top: "-9px" }} onClick={onCloseEditor}>
          <CloseIcon />
        </IconButton>
      </div>
      <InputGroup>
        <Label>
          <FormattedMessage
            id="editor.map.link.target"
            defaultMessage="Target"
            description="Text for map link target input label"
          />
        </Label>
        <SpinnerWrapper loading={targetInputLoading}>
          <div style={{ display: "flex", gap: "4px" }}>
            <Select
              value={selectedLink.item}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                setTarget(Number((e.target as HTMLSelectElement).value));
              }}
            >
              {(targets as (EditorMapPano | EditorMapPoi)[]).map(
                (linkTarget: EditorMapPano | EditorMapPoi, index: number) => (
                  <option key={index} value={"pano" in linkTarget ? linkTarget.pano : linkTarget.poi}>
                    {linkTarget.title}
                  </option>
                )
              )}
            </Select>
            {selectedLink.type === "pano" && (linkData as EditorMapPano)?.pano && (
              <IconButton onClick={() => {
                const pano = state.panos.find((pano) => pano.id === (linkData as EditorMapPano)?.pano);
                if (pano) {
                  history.push("/editor/pano/" + (linkData as EditorMapPano)?.pano + "/" + pano.scenarios[0].slug);
                }
              }}>
                <OpenIcon />
              </IconButton>
            )}
          </div>
        </SpinnerWrapper>
      </InputGroup>
      {/* <InputGroup>
        <Label>
          <FormattedMessage
            id="editor.link.shape"
            defaultMessage="Shape"
            description="Text for shape input label"
          />
        </Label>
        <SpinnerWrapper loading={colorInputLoading}>
          <Select
            value={linkData?.hotspot_type}
            onChange={(e: React.FormEvent<HTMLSelectElement>) => {
              setColor((e.target as HTMLSelectElement).value);
            }}
          >

          </Select>
        </SpinnerWrapper>
      </InputGroup> */}
      <SpinnerWrapper loading={coordinateInputsLoading}>
        <InputGroup>
          <Label>
            <FormattedMessage
              id="editor.map.link.lat"
              defaultMessage="Latitude"
              description="Text for map link latitude input label"
            />
          </Label>
          <Input
            type="text"
            placeholder="Lat"
            value={lat}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              if (/^[\d.-]*$/.test(value)) {
                setLatValue(value);
              }
            }}
            onBlur={() => {
              const formattedValue = lat.replace(",", "");
              if (/^-?\d*\.?\d*$/.test(formattedValue)) {
                setLat(parseFloat(formattedValue));
              } else {
                setLatValue(linkData.lat.toString() || "0");
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                const formattedValue = lat.replace(",", "");
                if (/^-?\d*\.?\d*$/.test(formattedValue)) {
                  setLat(parseFloat(formattedValue));
                } else {
                  setLatValue(linkData.lat.toString());
                }
              }
            }}
          />
        </InputGroup>
        <InputGroup>
          <Label>
            <FormattedMessage
              id="editor.map.link.long"
              defaultMessage="Longitude"
              description="Text for map link longitude input label"
            />
          </Label>
          <Input
            type="text"
            placeholder="Long"
            value={long}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              if (/^[\d.-]*$/.test(value)) {
                setLongValue(value);
              }
            }}
            onBlur={() => {
              const formattedValue = long.replace(",", "");
              if (/^-?\d*\.?\d*$/.test(formattedValue)) {
                setLong(parseFloat(formattedValue));
              } else {
                setLongValue(linkData.long.toString() || "");
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                const formattedValue = long.replace(",", "");
                if (/^-?\d*\.?\d*$/.test(formattedValue)) {
                  setLong(parseFloat(formattedValue));
                } else {
                  setLongValue(linkData.long.toString() || "");
                }
              }
            }}
          />
        </InputGroup>
      </SpinnerWrapper>
      <Hr />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={() => setDeleteLink(true)}>
          <TrashIcon />
          <FormattedMessage
            id="editor.link.delete"
            defaultMessage="Delete link"
            description="Text for delete link button"
          />
        </Button>
      </div>
      <ConfirmDialog show={deleteLink} setShow={setDeleteLink} onConfirm={onDeleteLink}>
        <h2>
          <FormattedMessage
            id="editor.link.confirm_delete"
            defaultMessage="Are you sure you want to delete this link?"
            description="Confirmation message for deleting a link"
          />
        </h2>
      </ConfirmDialog>
    </>
  );
};

export default MapLinkEditor;
