import React, { useState, useRef, useLayoutEffect, useEffect, useCallback, useMemo } from "react";
import { baseXml, generateLabelURI } from "../../../utils/panoXmlUtils";
import { getPanoIcon, getPoiIcon, removeHotspot } from "../../../utils/panoUtils";
import { Hotspot, Pano, PanoScenario, UseStateReturn } from "../../../types";
import { useRouteState } from "../../../hooks/useRouteState";
import { ReactComponent as LoaderIcon } from "./../../../images/loader.svg";
import debounce from "lodash.debounce";
import { PanoMedia, Loader } from "../../Panorama/PanoStyles";
import { useInterfaceState } from "../../../InterfaceContext";
import { useEditState } from "../EditContext";
import { useAppState } from "../../../AppContext";
import { useSessionStorage } from "../../../hooks/useStorage";
import {
  areNumbersEqual,
  EditorData,
  EditorPanoLink,
  EditorPanoPoi,
  SelectedLink,
} from "../../../utils/editorUtils";
import { usePanoUrl } from "../../../hooks/usePanoUrl";
import { getTokenValue } from "../../../TokenStyles";
import { MarkerTypes } from "../../../shared/components/MarkerImages";
import { useIntl } from "react-intl";
import { Krpano as KrpanoNS, KrpanoElement } from "../../../libs/krpano";
import { usePoiUrl } from "../../../hooks/usePoiUrl";

require("../../../libs/krpano.js");
declare const embedpano: (params: KrpanoNS.EmbedParameters) => void;
declare const removepano: (id: string) => void;

export const krpanoId = "editorKrpano";

export type View = {
  vlookat: number;
  hlookat: number;
  fov: number;
};

export type EditorVertexHotspot = KrpanoNS.Hotspot & {
  relatedHotspot: KrpanoNS.Hotspot;
  vertexIndex: number;
};

type KrpanoProps = View & {
  showFade: boolean;
  fade: UseStateReturn<boolean>;
  xml: string;
  onViewChange?: (view: View) => void;
  pano?: Pano;
  scenario?: PanoScenario;
  hotspots?: Hotspot[];
  selectedLink: SelectedLink | undefined;
  setSelectedLink: React.Dispatch<React.SetStateAction<SelectedLink | undefined>>;
  children: (krpano: KrpanoElement) => void;
};

const hlookatConverter = (value: number): number => {
  value = value % 360;
  if (value < 0) value = value + 360;
  return value;
};

/** Some krpano values are slightly, slightly different from the previous values */
const significantDifference = (a: number, b: number): boolean => Math.abs(a - b) > 0.01;

const krpanoContainerId = "pano";
const KrpanoViewer = React.memo(() => <div id={krpanoContainerId} />);

const EditorKrpano = ({
  xml,
  onViewChange,
  pano,
  scenario,
  selectedLink,
  setSelectedLink,
  hlookat,
  vlookat,
  fov,
  children,
}: KrpanoProps) => {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [krpanoContainer, setKrpanoContainer] = useState(() =>
    document.getElementById(krpanoContainerId)
  );
  const { setInterfaceState } = useInterfaceState();
  const prevSlug = useRef<string>("");
  const hasPreviousPano = useRef<boolean>(false);
  const prevScenarioSlug = useRef<string>("");
  const prevPoiSlug = useRef<string | null>(null);
  // Return to pano after video play
  const routeState = useRouteState(true);
  const { target, data } = useEditState();
  const { state } = useAppState();
  const [panoDragged] = useSessionStorage("panoDragged");
  const panoUrl = usePanoUrl();
  const poiUrl = usePoiUrl();
  const intl = useIntl();
  const lastPointerEventRef = useRef<PointerEvent>();

  const krpano = document.querySelector<KrpanoElement>(`#${krpanoId}`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setKrpanoContainer(document.getElementById(krpanoContainerId));
  });

  useEffect(() => {
    if (init && krpano) {
      krpano.focus();
    }
  }, [init, krpano]);

  const embedKrpano = useCallback(
    async (container: HTMLElement) => {
      if (document.hidden) {
        await new Promise<void>((resolve) => {
          const listener = () => {
            document.removeEventListener("visibilitychange", listener);
            resolve();
          };
          document.addEventListener("visibilitychange", listener);
        });
      }

      if (!document.getElementById(krpanoId)) {
        return new Promise<void>((resolve) => {
          embedpano({
            xml: null,
            consolelog: process.env.NODE_ENV === "development",
            id: krpanoId,
            target: container,
            onready: (krpanoInterface: KrpanoNS.Global) =>
              requestAnimationFrame(() => {
                const krpano = document.querySelector<KrpanoElement>(`#${krpanoId}`)!;
                const idletime = state.teaseIdletime;

                krpanoInterface.actions.loadxml(
                  baseXml({ idletime, fov, vlookat, hlookat }),
                  null,
                  null,
                  null,
                  () => resolve()
                );
                krpano.setAttribute("tabIndex", "0");
                krpano.setAttribute("data-html2canvas-ignore", "");

                krpanoInterface.display.customFullscreenElement = document.body;

                setLoading(false);
              }),
          });
        });
      }
    },
    [fov, vlookat, hlookat, state.teaseIdletime]
  );

  useEffect(() => {
    if (!krpanoContainer) return;

    let isCurrentRender = true;

    const timeout = window.setTimeout(async () => {
      if (isCurrentRender) {
        await embedKrpano(krpanoContainer);
        if (isCurrentRender) setInit(true);
      }
    }, 50);

    return () => {
      window.clearTimeout(timeout);
      isCurrentRender = false;
    };
  }, [krpanoContainer, embedKrpano]);

  useLayoutEffect(() => {
    if (krpanoContainer)
      return () => {
        // Keep state when hot-reloading
        if (!krpanoContainer.isConnected) {
          setInterfaceState((currentState) => ({ ...currentState, question: undefined }));
          removepano(krpanoId);
          setInit(false);
        }
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [krpanoContainer]);

  const hotspotExists = useCallback(
    (hotspotName: string) => {
      return Boolean(krpano?.get(`hotspot[${hotspotName}]`));
    },
    [krpano]
  );

  const renderHotspot = useCallback(
    (itemType: string, location: EditorPanoLink | EditorPanoPoi, i: number): void => {
      if (!krpano || !pano || !scenario) return;

      const linkedItem =
        itemType === "pano"
          ? state.panos.find((i) => i.id === (location as EditorPanoLink).panorama)
          : state.pois.find((i) => i.id === (location as EditorPanoPoi).poi);
      const isPoly = location.hotspot_type !== "point";
      const panoScenario = pano?.scenarios.find((s) => s.id === scenario.id);

      krpano.call(`addhotspot(editor-${itemType}-${i})`);
      const editorHotspot = krpano.get(`hotspot[editor-${itemType}-${i}]`);
      if (!editorHotspot) {
        return;
      }

      const hotspotName = `editor-${itemType}-${i}`;
      krpano.call(`addhotspot(${hotspotName})`);
      location.hotspot_coordinates.forEach(({ pitch, yaw }, vertexIndex) => {
        krpano.set(`hotspot[${hotspotName}].point[${vertexIndex}].ath`, yaw);
        krpano.set(`hotspot[${hotspotName}].point[${vertexIndex}].atv`, pitch);
      });

      editorHotspot.bordercolor = 0;
      editorHotspot.borderwidth = 3;
      editorHotspot.borderalpha = 1;
      editorHotspot.fillcolor = parseInt(
        getTokenValue(panoScenario ? "--color-success-50" : "--color-error-50").substring(1),
        16
      );
      editorHotspot.fillalpha = 0.25;
      editorHotspot.polyline = location.hotspot_type === "polyline";
      editorHotspot.alpha = (selectedLink && (selectedLink.type !== itemType || selectedLink.item !== i)) ? 0.4 : 1;

      if (isPoly) {
        location.hotspot_coordinates.forEach(({ pitch, yaw }, vertexIndex) => {
          krpano.set(`hotspot[${editorHotspot.name}].point[${vertexIndex}].ath`, yaw);
          krpano.set(`hotspot[${editorHotspot.name}].point[${vertexIndex}].atv`, pitch);

          if (!hotspotExists(`editor-${itemType}-${i}-${vertexIndex}`)) {
            krpano.call(`addhotspot(editor-${itemType}-${i}-${vertexIndex})`);
          }

          let vertexColor = "--color-neutral-50";
          if (!vertexIndex) vertexColor = "--color-success-50";
          if (vertexIndex === location.hotspot_coordinates.length - 1)
            vertexColor = "--color-neutral-70";

          const vertex = krpano.get(
            `hotspot[editor-${itemType}-${i}-${vertexIndex}]`
          ) as EditorVertexHotspot;
          vertex.url =
            "data:image/svg+xml;utf8," +
            encodeURIComponent(getPoiIcon(MarkerTypes.Edit, vertexColor, (vertexIndex + 1).toString()));
          vertex.width = 35;
          vertex.height = "prop";
          vertex.oversampling = 3;
          vertex.ath = yaw;
          vertex.atv = pitch;
          vertex.ondown = "draghotspot()";
          vertex.relatedHotspot = editorHotspot;
          vertex.vertexIndex = vertexIndex;
          vertex.capture = true;
          vertex.alpha = (selectedLink && (selectedLink.type !== itemType || selectedLink.item !== i)) ? 0.4 : 1;
          vertex.onup = () => {
            if (target === pano.id && data) {
              const editorData = data as unknown as EditorData<"pano">;
              const hotspots =
                itemType === "pano" ? editorData.panorama_location : editorData.poi_location;
              const item = hotspots[i];
              const epsilon = 0.5;
              if (
                areNumbersEqual(
                  item.hotspot_coordinates[vertexIndex]["pitch"],
                  vertex.atv,
                  epsilon
                ) &&
                areNumbersEqual(item.hotspot_coordinates[vertexIndex]["yaw"], vertex.ath, epsilon)
              ) {
                setSelectedLink({ type: itemType, item: i });
              } else {
                document.dispatchEvent(new CustomEvent("hotspotdragged", { detail: vertex }));
              }
            }
          };
        });
      } else {
        if (itemType === "pano") {
          const panoIcon = linkedItem
            ? linkedItem && "icon" in linkedItem && (!linkedItem.icon || linkedItem.icon === "auto")
              ? panoScenario?.isVideo
                ? "video"
                : "pano"
              : linkedItem && "icon" in linkedItem && linkedItem?.icon
            : "pano";
          editorHotspot.url =
            "data:image/svg+xml;utf8," +
            encodeURIComponent(
              getPanoIcon(
                (linkedItem?.type as "low" | "high") || "low",
                panoIcon && panoIcon !== "auto" ? panoIcon : "pano",
                getTokenValue(panoScenario ? "--color-success-50" : "--color-error-50")
              )
            );
        } else {
          editorHotspot.url =
            "data:image/svg+xml;utf8," +
            encodeURIComponent(
              getPoiIcon(
                (linkedItem?.type as MarkerTypes) || "defaultType",
                getTokenValue(panoScenario ? "--color-success-50" : "--color-error-50")
              )
            );
        }
        switch (linkedItem?.type) {
          case "info":
            editorHotspot.width = 30;
            break;
          case "low":
            editorHotspot.width = 50;
            break;
          case "high":
          default:
            editorHotspot.width = 44;
            break;
        }
        editorHotspot.height = "prop";
        editorHotspot.oversampling = 3;
        editorHotspot.ath = location.yaw;
        editorHotspot.atv = location.pitch;
        editorHotspot.onout = `tween(scale, 1); tween(hotspot[editor-${itemType}-${i}-text].scale, 1)`;
        editorHotspot.onhover = `tween(scale, 1.1); tween(hotspot[editor-${itemType}-${i}-text].scale, 1.1)`;
        editorHotspot.ondown = "draghotspot()";
        editorHotspot.onup = () => {
          if (data) {
            const editorData = data as unknown as EditorData<"pano">;
            const hotspots =
              itemType === "pano" ? editorData.panorama_location : editorData.poi_location;
            const item = hotspots[i];
            const epsilon = 0.5;
            if (
              areNumbersEqual(item.pitch, editorHotspot.atv, epsilon) &&
              areNumbersEqual(item.yaw, editorHotspot.ath, epsilon)
            ) {
              setSelectedLink({ type: itemType, item: i });
            } else {
              document.dispatchEvent(new CustomEvent("hotspotdragged", { detail: editorHotspot }));
            }
          }
        };
      }

      editorHotspot.capture = true;
      editorHotspot.linkurl = linkedItem
        ? itemType === "pano"
          ? panoUrl(linkedItem.slug)
          : poiUrl(linkedItem.slug)
        : "";

      krpano.call(`addhotspot(editor-${itemType}-${i}-text)`);
      const editorHotspotLabel = krpano.get(`hotspot[editor-${itemType}-${i}-text]`);
      if (!editorHotspotLabel) return;
      let labelText = ``;
      if (linkedItem) {
        labelText += `${linkedItem.title} `;
      } else {
        labelText += intl.formatMessage({
          id: "pano.edit-mode.location-unassigned",
          defaultMessage: "Location unassigned.",
          description:
            "Hotspot label for an editable hotspot which does not have a location assigned to it.",
        });
      }
      const label = generateLabelURI(labelText);
      editorHotspotLabel.url = label.svg;
      editorHotspotLabel.width = label.width;
      editorHotspotLabel.height = "prop";
      editorHotspotLabel.ath = location.yaw;
      editorHotspotLabel.atv = location.pitch;
      editorHotspotLabel.edge = "left";
      editorHotspotLabel.alpha = (selectedLink && (selectedLink.type !== itemType || selectedLink.item !== i)) ? 0.4 : 1;
      editorHotspotLabel.ox = linkedItem?.type === "high" ? 22 : 25;
      editorHotspotLabel.onout = `tween(hotspot[editor-${itemType}-${i}].scale, 1); tween(scale, 1)`;
      editorHotspotLabel.capture = false;
      editorHotspotLabel.onhover = `tween(hotspot[editor-${itemType}-${i}].scale, 1.1); tween(scale, 1.1)`;
    },
    [krpano, pano, scenario, state.panos, state.pois, hotspotExists, selectedLink, panoUrl, poiUrl, target, data, setSelectedLink, intl]
  );

  useEffect(() => {
    if (!krpano || !pano || !scenario) return;

    if (target === pano.id && data) {
      krpano
        .get("hotspot")
        .getArray()
        .slice()
        .forEach((h: any) => {console.log(h.name); krpano.call(`removehotspot(${h.name})`)});
      
      const editorData = data as EditorData<"pano">;
      const editorPanoLocations = editorData.panorama_location;
      const editorPoiLocations = editorData.poi_location;

      if (editorPanoLocations) {  
        editorPanoLocations.forEach((location, i) => {
          const currentPano = state.panos.find((p) => p.id === location.panorama)
          if (currentPano && currentPano.scenarios.find((s) => s.slug === scenario.slug)) {
            renderHotspot("pano", location, i);
          }
        });
      }

      if (editorPoiLocations) {
        editorPoiLocations.forEach((location, i) => {
          const currentPoi = state.pois.find((p) => p.id === location.poi)
          if (currentPoi && currentPoi?.scenarios.some((s) => s === scenario.id)) {
            renderHotspot("poi", location, i);
          }
        });
      }

    }
  }, [pano, target, data, panoUrl, state.panos, hotspotExists, scenario, intl, krpano, renderHotspot, state.pois]);

  const triggerOnChange = useMemo(
    () =>
      debounce((nVlookat: number, nHlookat: number, nFov: number) => {
        if (
          significantDifference(hlookat, nHlookat) ||
          significantDifference(vlookat, nVlookat) ||
          significantDifference(fov, nFov)
        ) {
          onViewChange?.({
            hlookat: hlookatConverter(nHlookat),
            vlookat: nVlookat,
            fov: nFov,
          });
        }
      }, 200),
    [hlookat, vlookat, fov, onViewChange]
  );

  const viewChange = useCallback(() => {
    if (!krpano || krpano.get("webvr.isenabled")) return;
    const view = krpano.get("view");
    if (view.vlookat !== vlookat || view.hlookat !== hlookat || view.fov !== fov) {
      triggerOnChange(view.vlookat, view.hlookat, view.fov);
    }
  }, [hlookat, vlookat, fov, triggerOnChange, krpano]);

  useLayoutEffect(() => {
    if (init && krpano) {
      krpano.set("events.onviewchanged", viewChange);

      prevPoiSlug.current = null;
    }

    return triggerOnChange.cancel;
  }, [init, viewChange, routeState.poiSlug, triggerOnChange.cancel, panoDragged, krpano, data]);

  const onXmlComplete = useCallback(() => {
    if (!krpano) return;
    const view = krpano.get("view");
    view.hlookat = hlookat;
  }, [krpano, hlookat]);

  useLayoutEffect(() => {
    if (init && krpano) {
      if (
        pano &&
        scenario &&
        (prevSlug.current !== pano.slug || prevScenarioSlug.current !== scenario.slug)
      ) {
        const isNewLocation = prevSlug.current !== pano.slug;
        let isBothIndoor = isNewLocation && pano.isIndoor;
        if (isBothIndoor && hasPreviousPano.current) {
          const oldLocation = state.panos.find((p) => p.slug === prevSlug.current)!;
          isBothIndoor = oldLocation.isIndoor;
        }
        const merge = "KEEPMOVING";
        let blend = isNewLocation
          ? "ZOOMBLEND(1.2, 1.2)"
          : "SLIDEBLEND(0.6, 180, 0.4, easeinoutquad)";

        krpano.call(`loadxml(${xml}, null, ${merge}, ${blend});`);
        if (prevSlug.current) hasPreviousPano.current = true;
        prevSlug.current = pano.slug;
        prevScenarioSlug.current = scenario.slug;
      } else {
        krpano.set("events.onxmlcomplete", null);
      }
    }
  }, [init, pano, scenario, xml, krpano, state.panos, onXmlComplete]);

  return (
    <PanoMedia
      onPointerUpCapture={(event: any) => (lastPointerEventRef.current = event.nativeEvent)}
    >
      <KrpanoViewer />
      {(!krpano || loading) && (
        <Loader>
          <LoaderIcon />
        </Loader>
      )}
      {krpano && children(krpano)}
    </PanoMedia>
  );
};

export default React.memo(EditorKrpano);
